import EchoCore, { AuthenticationResult } from '@equinor/echo-core';
import { getDeepLinkParams } from '@equinor/echo-utils';

export function initForE2ETests() {
    const { skipAuth } = getDeepLinkParams(['skipAuth']);
    if (skipAuth?.toLowerCase() === 'true') {
        setupSkipAuth();
    }
}

const setAuthentication = () => {
    EchoCore.EchoAuthProvider.isAuthenticated = true;
    EchoCore.EchoAuthProvider.userProperties = {
        account: {
            homeAccountId: 'homeAccountId',
            environment: 'testingEnvironment',
            tenantId: 'tenantId',
            username: 'tester',
            localAccountId: 'tester123'
        }
    };
};

export function setupSkipAuth() {
    EchoCore.EchoAuthProvider.isAuthenticated = true;
    EchoCore.EchoAuthProvider.handleLogin = async (logRequest?: (...args: unknown[]) => void): Promise<void> => {
        setAuthentication();
        return Promise.resolve(undefined);
    };
    EchoCore.EchoAuthProvider.ssoSilentOrRedirectToAuthenticate = async () => {
        setAuthentication();
        return Promise.resolve(undefined);
    };
    EchoCore.EchoAuthProvider.aquireTokenSilentOrRedirectToAuthenticate =
        async (): Promise<AuthenticationResult | null> => {
            setAuthentication();
            return null;
        };
    EchoCore.EchoAuthProvider.login = async () => {
        setAuthentication();
        return Promise.resolve();
    };
}
