import { EchoEnv, Plant, request, setPlantsData } from '@equinor/echo-core';

async function getPlantsFromApi(): Promise<Plant[] | undefined> {
    const url = `${EchoEnv.env().REACT_APP_API_URL}/plants`;
    return request<Plant[]>({
        url, cache: {
            timeInSeconds: 4 * 60 * 60 // 4 hours
        }
    });
}

export async function getCorePlants(): Promise<void> {
    try {
        const apiPlants = (await getPlantsFromApi() ?? []);

        if (apiPlants && apiPlants.length > 0) {
            setPlantsData({ plants: apiPlants });
        }
    } catch (ex) {
        console.error(ex);
    }
}
