// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Applied in echo application by bootstrap so it should exist here to */
*,
:after,
:before {
    box-sizing: border-box;
}

#root {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA,wEAAwE;AACxE;;;IAGI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["/* Applied in echo application by bootstrap so it should exist here to */\n*,\n:after,\n:before {\n    box-sizing: border-box;\n}\n\n#root {\n    height: 100%;\n    overflow: hidden;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
